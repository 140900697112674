import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>Depoimentos</h2>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div class="video-container">
              <iframe
                src="https://www.youtube.com/embed/COpe07aYiRk"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div class="video-container">
              <iframe
                src="https://www.youtube.com/embed/x9KqTQCCntM"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div class="video-container">
              <iframe
                src="https://www.youtube.com/embed/ktyLQs9a-XQ"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div class="video-container">
              <iframe
                src="https://www.youtube.com/embed/uEb806SvDq4"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
