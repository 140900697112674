import React from "react";
import "./style.scss";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>O Investimento</h2>
          <p>Oferta por tempo limitado!</p>
        </div>
        <div className="cards">
          <div className="card-pricing">
            <img src="img/logo.png" className="logo-card" />
            <h4>12x R$166,41</h4>
            <div className="botao">
              <div>
                <a
                  target="_blank"
                  href="https://pay.hotmart.com/R81282840E?sck=HOTMART_PRODUCT_PAGE&off=9l349taw&hotfeature=32&bid=1683641438342"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Adquirir
                </a>
              </div>
              <p>Mais de 100 aulas</p>
              <p>Certificado de Conclusão</p>
              <p>Grupo no Whatsapp</p>
              <p>Novos Conteúdos Semanais</p>
              <p>Curso de Snooker mais completo do mundo</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
